<template>
  <div class="resetPassword">
    <form @submit.prevent="submitHandler">
      <a-card title="Herstel je wachtwoord"
              :bordered="false"
              :hoverable="true"
              class="resetPassword__card">
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.resetPasswordForm.password.name.$dirty && $v.resetPasswordForm.password.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
            <span
              v-show="$v.resetPasswordForm.password.name.$dirty && $v.resetPasswordForm.password.name.$invalid"
            >
              {{ `Onjuiste invoer` }}
            </span>
            </template>
            <a-input-password
              allow-clear
              id="password"
              v-model="resetPasswordForm.password.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('password', true, 'resetPasswordForm')"
              @blur="focusHandler('password', false, 'resetPasswordForm')"
            />
          </a-form-item>

          <label for="password"
                 class="formWrapper__form--label"
                 :class="resetPasswordForm.password.isFocused ? 'focused' : ''"
          >
            Nieuw wachtwoord
          </label>
        </div>
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.resetPasswordForm.repeatPassword.name.$dirty && $v.resetPasswordForm.repeatPassword.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
            <span
              v-show="$v.resetPasswordForm.repeatPassword.name.$dirty && $v.resetPasswordForm.repeatPassword.name.$invalid"
            >
              {{ `Onjuiste invoer` }}
                {{ $v.resetPasswordForm.repeatPassword.sameAsPassword }}
            </span>
            </template>
            <a-input-password
              allow-clear
              id="repeatPassword"
              v-model="resetPasswordForm.repeatPassword.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('repeatPassword', true, 'resetPasswordForm')"
              @blur="focusHandler('repeatPassword', false, 'resetPasswordForm')"
            />
          </a-form-item>

          <label for="repeatPassword"
                 class="formWrapper__form--label"
                 :class="resetPasswordForm.repeatPassword.isFocused ? 'focused' : ''"
          >
            Herhaal je nieuwe wachtwoord
          </label>
        </div>
        <div
          v-show="resetPasswordForm.errorMessage"
          style="margin-bottom: 10px"
        >
          <a-alert type="error"
                   :message="resetPasswordForm.errorMessage"
                   banner
          />
        </div>
        <a-button type="primary"
                  :loading="resetPasswordForm.isLoading"
                  htmlType="submit"
                  style="margin-top: 10px"
        >
          Opslaan!
          <a-icon type="save" />
        </a-button>
      </a-card>
    </form>
  </div>
</template>

<script>
import focus from "@/mixins/focus";
import {sameAs, minLength, required} from "vuelidate/lib/validators";
import {mapActions} from "vuex";

export default {
  name: 'ResetPassword',
  mixins: [focus],
  data() {
    return {
      resetPasswordForm: {
        password: {
          name: '',
          isFocused: ''
        },
        repeatPassword: {
          name: '',
          isFocused: ''
        },
        isLoading: false,
        errorMessage: ''
      }
    }
  },
  validations: {
    resetPasswordForm: {
      password: {
        name: {
          required,
          minLength: minLength(10)
        }
      },
      repeatPassword: {
        name: {
          required,
          minLength: minLength(10),
          sameAsPassword: sameAs(function () {
            return this.resetPasswordForm.password.name
          })
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'resetPassword'
    ]),
    submitHandler() {
      this.$v.resetPasswordForm.$touch()
      if (!this.$v.resetPasswordForm.$invalid) {
        let token = this.$route.params.token
        let email = this.$route.params.email

        this.resetPasswordForm.isLoading = true
        this.resetPassword({
          token: token ? token : '',
          email: email ? email : '',
          password: this.resetPasswordForm.password.name,
          password_confirmation: this.resetPasswordForm.repeatPassword.name,
        }).then(e => {
          if (!e.ok) {
            this.resetPasswordForm.errorMessage = e.message || 'Wachtwoord kan niet hersteld worden'
            return
          }
          this.$router.push({name: 'Login'})
        }).catch(e => {
          this.resetPasswordForm.errorMessage = e.message || 'Wachtwoord kan niet hersteld worden'
        }).finally(() => {
            this.resetPasswordForm.isLoading = false
            setTimeout(() => this.resetPasswordForm.errorMessage = '', 5000)
        })
      }
    }
  }
}
</script>

<style lang="scss">
.resetPassword {
  width: 100%;
  background: rgba(blue, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.resetPassword__card {
  border-radius: 30px !important;
  min-width: 300px;
  width: 600px;
  -webkit-box-shadow: 0px 0px 25px -5px rgba(#FFA300, 1);
  -moz-box-shadow: 0px 0px 25px -5px rgba(#FFA300, 1);
  box-shadow: 0px 0px 25px -5px rgba(#FFA300, 1);
  padding: 30px;
}
</style>
